











































import Dialog from '@/calendesk/models/Dialog'
import dialogTypes from '@/components/dialogs/dialogTypes'
import dialogSize from '@/lib/calendesk-js-library/components/dialogs/dialogSize'
import mixins from 'vue-typed-mixins'
import DomainActions from '@/components/dialogs/components/domains/mixins/DomainActions'
export default mixins(DomainActions).extend({
  name: 'DomainDialog',
  created () {
    if (!this.defaultDomain) {
      this.openDomainDialogHandle()
    }
  },
  methods: {
    openDomainDialogHandle () {
      this.openDialog(new Dialog(true, dialogTypes.BUILDER_UPDATE_DOMAIN, this.$trans('domain_configuration_title'), dialogSize.LARGE))
    }
  }
})
